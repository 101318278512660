import React from 'react'

const SvgArea = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <title>{'icon / area'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M1 1h18v18H1z" />
      <path
        d="M10 2a5.5 5.5 0 00-5.5 5.5c0 1.98 1.696 5.373 5.089 10.284a.5.5 0 00.822 0l.536-.783C13.982 12.511 15.5 9.373 15.5 7.5A5.5 5.5 0 0010 2zm0 1a4.5 4.5 0 014.5 4.5l-.004.141c-.076 1.542-1.372 4.254-3.889 8.067l-.607.907-.105-.155C6.965 12.126 5.5 9.11 5.5 7.5A4.5 4.5 0 0110 3z"
        fill="#38383F"
        fillRule="nonzero"
      />
      <path
        d="M10 4c1.495 0 2.81.946 3.3 2.333a.5.5 0 11-.942.334 2.501 2.501 0 00-4.716 0 .5.5 0 01-.943-.334A3.501 3.501 0 0110 4zM10 11a3.501 3.501 0 01-3.3-2.333.5.5 0 11.942-.333 2.501 2.501 0 004.716 0 .5.5 0 11.943.333A3.501 3.501 0 0110 11z"
        fill="#38383F"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default SvgArea
