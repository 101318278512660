import React from "react";
import { graphql } from "gatsby";
import SEO from "Components/SEO";
import List from "../features/List";

function ListTemplate({ data, pageContext }) {
  const { sanityLists: list } = data;
  return (
    <>
      <SEO
        title={`${list.title}`}
        imageSrc={list.image.asset.fluid?.src}
        imageHeight={list.image.asset.fixed?.height}
        imageWidth={list.image.asset.fixed?.width}
        description={list._rawDescription[0].children[0].marks.text}
      />
      <List activeDate={list.activeDate.local} dateRangeLowerLimit={pageContext.dateRangeLowerLimit} list={list} />
    </>
  );
}

export default ListTemplate;

export const pageQuery = graphql`
  query ListById($id: String!) {
    sanityLists(id: { eq: $id }) {
      id
      _id
      customId
      activeDate {
        local
      }
      slug {
        current
      }
      title
      _updatedAt(formatString: "MMMM Do, YYYY")
      _rawDescription
      image {
        asset {
          fluid(maxHeight: 544, maxWidth: 1000) {
            ...GatsbySanityImageFluid
          }
          fixed {
            height
            width
          }
        }
      }
      experienceStop {
        _rawDescription
        place {
          ...placeFields
        }
        event {
          ...eventFields
        }
      }
      tags {
        name
      }
    }
  }
`;
