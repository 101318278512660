import React from 'react'

const SvgWebsite = (props) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <title>Link</title>
    <g
      id="icon-/-internet"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="1" y="1" width="18" height="18"></rect>
      <path
        d="M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z M10,3 C6.13400675,3 3,6.13400675 3,10 C3,13.8659932 6.13400675,17 10,17 C13.8659932,17 17,13.8659932 17,10 C17,6.13400675 13.8659932,3 10,3 Z"
        id="Oval"
        fill="#38383F"
        fillRule="nonzero"
      ></path>
      <path
        d="M10.0001777,2.11075093 C11.9329085,2.11075093 13.4141997,4.80643335 13.8249193,8.53434166 C13.8551601,8.80882318 13.6571639,9.05584943 13.3826824,9.08609024 C13.1082009,9.11633104 12.8611746,8.91833482 12.8309338,8.6438533 C12.4703537,5.37103801 11.2283145,3.11075093 10.0001777,3.11075093 C8.77842396,3.11075093 7.53556564,5.38008209 7.1715196,8.64982497 C7.14096331,8.92427155 6.89370964,9.1219837 6.61926306,9.09142741 C6.34481648,9.06087113 6.14710433,8.81361746 6.17766062,8.53917088 C6.59241275,4.8140024 8.07291884,2.11075093 10.0001777,2.11075093 Z"
        id="Path"
        fill="#38383F"
        fillRule="nonzero"
      ></path>
      <path
        d="M10.0001777,10.9055021 C11.9329085,10.9055021 13.4141997,13.6011845 13.8249193,17.3290928 C13.8551601,17.6035743 13.6571639,17.8506006 13.3826824,17.8808414 C13.1082009,17.9110822 12.8611746,17.713086 12.8309338,17.4386045 C12.4703537,14.1657892 11.2283145,11.9055021 10.0001777,11.9055021 C8.77842396,11.9055021 7.53556564,14.1748332 7.1715196,17.4445761 C7.14096331,17.7190227 6.89370964,17.9167348 6.61926306,17.8861786 C6.34481648,17.8556223 6.14710433,17.6083686 6.17766062,17.333922 C6.59241275,13.6087536 8.07291884,10.9055021 10.0001777,10.9055021 Z"
        id="Path"
        fill="#38383F"
        fillRule="nonzero"
        transform="translate(10.001258, 14.397398) rotate(-180.000000) translate(-10.001258, -14.397398) "
      ></path>
      <polygon
        id="Path-20"
        fill="#38383F"
        fillRule="nonzero"
        points="17.5951269 9.5 17.5951269 10.5 2.37466582 10.5 2.37466582 9.5"
      ></polygon>
      <polygon
        id="Path-19"
        fill="#38383F"
        fillRule="nonzero"
        points="9.50000025 2.388072 10.4999998 2.38906952 10.4848961 17.5302444 9.4848966 17.5292469"
      ></polygon>
    </g>
  </svg>
)

export default SvgWebsite
