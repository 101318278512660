import React from 'react'

const Ticket = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <title>{'icon / ticket'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <g fill="#FCFCFC" fillRule="nonzero">
        <path d="M8.732 5.61l-7.07 7.072a.5.5 0 000 .707l1.06 1.06a.5.5 0 00.707 0 1.5 1.5 0 012.121 2.122.5.5 0 000 .707l1.06 1.06a.5.5 0 00.708 0l7.071-7.07a.5.5 0 000-.707L9.44 5.61a.5.5 0 00-.707 0zm.354 1.062l4.242 4.242-6.364 6.364-.39-.39.077-.12a2.501 2.501 0 00-.394-3.025l-.12-.113a2.502 2.502 0 00-2.905-.281l-.121.075-.39-.388 6.365-6.364zM13.332 1.011L9.796 4.547a.5.5 0 000 .707l4.95 4.95a.5.5 0 00.707 0l3.536-3.536a.5.5 0 000-.707l-1.06-1.06a.5.5 0 00-.708 0A1.5 1.5 0 1115.1 2.778a.5.5 0 000-.707l-1.061-1.06a.5.5 0 00-.707 0zm.353 1.062l.389.388-.075.121a2.501 2.501 0 00.394 3.025l.12.113A2.502 2.502 0 0017.418 6l.12-.075.39.39-2.829 2.828L10.856 4.9l2.829-2.828z" />
        <path d="M7.186 11.024l-.636.853-.046.073a.5.5 0 00.453.726l1.065-.013.615.869.051.062a.5.5 0 00.835-.202l.315-1.018 1.018-.316.075-.03a.5.5 0 00.065-.856l-.87-.614.014-1.066-.006-.086a.5.5 0 00-.793-.321l-.853.637-1.01-.342a.5.5 0 00-.633.634l.34 1.01zm.93-.372l.3.102.094.022a.5.5 0 00.365-.095l.251-.189-.003.315.009.098a.5.5 0 00.203.317l.256.182-.3.093-.09.038a.5.5 0 00-.24.291l-.093.302-.182-.258-.063-.074a.5.5 0 00-.351-.137l-.316.003.19-.252a.5.5 0 00.072-.46l-.102-.298z" />
      </g>
    </g>
  </svg>
)

export default Ticket
