import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import Img from "gatsby-image";
// Components
import BackArrowButton from "Components/BackArrowButton";
import ShareButton from "Components/ShareButton";
import Stop from "./Stop";
import XupFooter from "Components/XupFooter";
import SocialIcon from "Components/SocialIcon";
import LikeButton from "Components/LikeButton";
// Svg
import FacebookIcon from "Svg/FacebookIcon";
import InstagramIcon from "Svg/InstagramIcon";
// Hooks
import useWindowWidth from "Hooks/useWindowWidth";
import useAuthState from "Hooks/useAuthState";
// Styles
import styles from "./list.module.scss";
import "./list.scss";

function List({ list, link = "", linkCheck = "" }) {
  let width = useWindowWidth();
  const { authState } = useAuthState();
  function Tags() {
    const tags = [];
    Object.keys(list.tags).forEach(function (key) {
      tags.push(
        <div className={styles.tag}>
          <p>{list.tags[key].name}</p>
        </div>
      );
    });
    return tags;
  }

  return (
    <>
      <section className={styles.list}>
        <section className={styles.listImageWrapper}>
          <Img alt={list.title} fluid={list.image?.asset.fluid} className={styles.listImage} />
          <BackArrowButton />
          <LikeButton user={authState.user} list={list} authState={authState} />
        </section>
        <section className={styles.info}>
          <section className={styles.firstSection}>
            <h1>{list.title}</h1>
            <BlockContent
              blocks={list._rawDescription}
              className={styles.description}
              renderContainerOnSingleChild={true}
            />
            <section className={styles.tags}>
              <Tags />
            </section>
          </section>
          <section className={styles.stops}>
            <ul className={styles.stopList}>
              {list.experienceStop?.map((stop, index) => (
                <Stop key={index} stop={stop} index={index} />
              ))}
            </ul>
          </section>
        </section>
        <section className={styles.shareBox}>
          <ShareButton />
        </section>
      </section>
      <footer className={styles.footer}>
        {width >= 1024 && (
          <section className={styles.social}>
            <SocialIcon link="https://www.instagram.com/xupapp/">
              <InstagramIcon />
            </SocialIcon>
            <SocialIcon link="https://www.facebook.com/whatsxup/">
              <FacebookIcon />
            </SocialIcon>
          </section>
        )}
        {width >= 1024 && (
          <section className={styles.links}>
            <XupFooter />
          </section>
        )}
        {width <= 1024 && (
          <section className={styles.links} style={{ width: "100%", marginTop: "30px" }}>
            <XupFooter />
          </section>
        )}
      </footer>
    </>
  );
}

export default List;
