import React, { useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import useAuthState from "Hooks/useAuthState";
import cx from "classnames";
import { Object, console } from "window-or-global";
import Heart from "Svg/Heart";
import HeartFilledWhite from "Svg/HeartFilledWhite";
import styles from "./likeButton.module.scss";

const FETCH_USER_LIKE_FOR_LIST = gql`
  query FETCH_USER_LIKE_FOR_LIST($list_id: String!, $user_id: String!) {
    list_likes_aggregate(where: { list_id: { _eq: $list_id }, user_id: { _eq: $user_id } }) {
      aggregate {
        count
      }
    }
  }
`;

const FETCH_LIKES_FOR_LIST = gql`
  query($list_id: String) {
    list_likes_aggregate(where: { list_id: { _eq: $list_id } }) {
      aggregate {
        count
      }
    }
  }
`;

const ADD_LIKE = gql`
  mutation($user_id: String!, $list_id: String!) {
    insert_list_likes(objects: { list_id: $list_id, user_id: $user_id }) {
      affected_rows
    }
  }
`;

const DELETE_LIKE = gql`
  mutation($user_id: String!, $list_id: String!) {
    delete_list_likes(where: { user_id: { _eq: $user_id }, list_id: { _eq: $list_id } }) {
      affected_rows
    }
  }
`;

export default function LikeButton({ list }, props) {
  const { authState, setAuthState } = useAuthState();
  const [isLikeButtonDisabled, setIsLikeButtonDisabled] = useState(false);

  const userEmail = authState.user?.email ?? "";
  const context = authState?.token ? { headers: { Authorization: `Bearer ${authState.token}` } } : {};

  const listVariables = {
    list_id: list.customId,
  };

  const userVariables = {
    list_id: list.customId,
    user_id: userEmail,
  };

  /**
   * Mutations
   */
  const [addLikeMutator, addLikeMutation] = useMutation(ADD_LIKE, {
    variables: userVariables,
    context,
    refetchQueries: [
      {
        query: FETCH_LIKES_FOR_LIST,
        variables: listVariables,
        context,
      },
      {
        query: FETCH_USER_LIKE_FOR_LIST,
        variables: userVariables,
        context,
      },
    ],
  });

  // delete like mutation
  const [removeLikeMutator, removeLikeMutation] = useMutation(DELETE_LIKE, {
    variables: userVariables,
    context,
    refetchQueries: [
      {
        query: FETCH_LIKES_FOR_LIST,
        variables: listVariables,
        context,
      },
      {
        query: FETCH_USER_LIKE_FOR_LIST,
        variables: userVariables,
        context,
      },
    ],
  });

  const listLikesQuery = useQuery(FETCH_LIKES_FOR_LIST, {
    variables: listVariables,
    context,
  });

  const userLikeQuery = useQuery(FETCH_USER_LIKE_FOR_LIST, {
    variables: userVariables,
  });

  const handleLike = async () => {
    await addLikeMutator();
    setIsLikeButtonDisabled(false);
  };

  const handleUnlike = async () => {
    await removeLikeMutator();
    setIsLikeButtonDisabled(false);
  };

  if (listLikesQuery.data && userLikeQuery.data) {
    const listLikeCount = listLikesQuery.data.list_likes_aggregate.aggregate.count;
    const userLikedList = userLikeQuery.data.list_likes_aggregate.aggregate.count > 0;
    return (
      <div className={cx("like-button", styles.likeButtonWrapper)}>
        {authState.status === "in" ? (
          <>
            {!userLikedList && (
              <button
                disabled={isLikeButtonDisabled}
                onClick={() => {
                  setIsLikeButtonDisabled(true);
                  handleLike();
                }}
              >
                {listLikeCount} Like This <Heart className={styles.notFilled} />
              </button>
            )}
            {userLikedList && (
              <button
                disabled={isLikeButtonDisabled}
                onClick={() => {
                  setIsLikeButtonDisabled(true);
                  handleUnlike();
                }}
              >
                {listLikeCount} Like This
                <HeartFilledWhite className={styles.filled} />
              </button>
            )}
          </>
        ) : (
          <button
            disabled={isLikeButtonDisabled}
            onClick={() => {
              document.getElementById("login").click();
            }}
          >
            {listLikeCount} Like This <Heart className={styles.notFilled} />
          </button>
        )}
      </div>
    );
  }

  return (
    <div className={cx("like-button", styles.likeButtonWrapper)}>
      <button />
    </div>
  );
}
