import React from 'react'

const SvgShare = (props) => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <title>{'icons / share'}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M1 1h18v18H1z" />
      <path
        d="M14 13.5a.5.5 0 01.492.41l.008.09v3a.5.5 0 01-.41.492L14 17.5H6a.5.5 0 01-.492-.41L5.5 17v-3a.5.5 0 01.992-.09L6.5 14v2.5h7V14a.5.5 0 01.41-.492L14 13.5zm-4-7a.5.5 0 01.492.41L10.5 7v7.5a.5.5 0 01-.992.09L9.5 14.5V7a.5.5 0 01.5-.5zm.284-3.568l.07.058 4.656 4.656a.5.5 0 01-.637.765l-.07-.057L10 4.05 5.697 8.354a.5.5 0 01-.638.057l-.07-.057a.5.5 0 01-.057-.638l.058-.07L9.646 2.99a.5.5 0 01.638-.058z"
        fill="#38383F"
      />
    </g>
  </svg>
)

export default SvgShare
