import React from "react";
import PropTypes from "prop-types";
import useWindowWidth from "Hooks/useWindowWidth";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import Modal from "react-modal";
import cx from "classnames";
import { getHumanizedDurationString } from "Utils/dateTimeFormatting";
import AreaIcon from "Svg/AreaIcon";
import Dismiss from "Svg/Dismiss";
import WebsiteIcon from "Svg/WebsiteIcon";
import Ticket from "Svg/Ticket";
import styles from "./stop.module.scss";

Stop.propTypes = {
  stop: PropTypes.object.isRequired,
};

const stopToColorMap = [
  { className: "first" },
  { className: "second" },
  { className: "third" },
  { className: "fourth" },
];

const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark;
      const href = `/${slug.current}`;
      return <a href={href}>{children}</a>;
    },
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

export default function Stop({ stop, index }) {
  console.log(stop);
  const isEvent = Boolean(stop.event);
  let image;
  let title;
  let priceRange;
  let description;
  let googleMapsPlaceId;
  let address;
  let website;
  let ticket;
  let link;
  let ePlace;

  if (isEvent) {
    const { event } = stop;
    image = event?.image || event?.place.image;
    title = event?.title || event?.place.title;
    priceRange = event?.priceRange || event?.place.priceRange;
    description = stop._rawDescription || event?._rawDescription || event?.place._rawDescription;
    googleMapsPlaceId = event?.place?.googleMapsPlaceId;
    address = event?.place?.address;
    website = event?.website || event?.place?.website;
    ticket = event?.ticket || event?.place?.ticket;
    ePlace = event?.place.title || event?.place?.title;
  } else {
    const { place } = stop;
    image = place.image;
    title = place.title;
    priceRange = place.priceRange;
    description = stop._rawDescription || place._rawDescription;
    googleMapsPlaceId = place.googleMapsPlaceId;
    address = place.address;
    website = place.website;
    ticket = place.ticket || place?.ticket;
    if (ticket == true) {
      link = place.ticketLink || place?.ticketLink;
    }
  }

  // Place Modal Functions

  function openPlaceModal() {
    setPlaceIsOpen(true);
  }
  const [placeModalIsOpen, setPlaceIsOpen] = React.useState(false);
  // const {authState, setAuthState, firebaseInstance} = useAuthState();
  function afterOpenPlaceModal() {
    // references are now sync'd and can be accessed.
  }

  function closePlaceModal() {
    setPlaceIsOpen(false);
  }
  let width = useWindowWidth();
  const customPlaceStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "30px",
    },
  };

  // Event Modal
  function openEventModal() {
    setEventIsOpen(true);
  }
  const [eventModalIsOpen, setEventIsOpen] = React.useState(false);
  // const {authState, setAuthState, firebaseInstance} = useAuthState();
  function afterOpenEventModal() {
    // references are now sync'd and can be accessed.
  }
  var subtitle;

  function closeEventModal() {
    setEventIsOpen(false);
  }

  const customEventStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "30px",
    },
  };

  function TicketButton() {
    if (ticket == true && link !== null) {
      return (
        <>
          <div className={styles.linksTicket}>
            <div className={styles.linkIcon} data-for="maps" data-tip="Google Maps">
              <a
                href={`https://www.google.com/maps/search/?api=1&query_place_id=${googleMapsPlaceId}&query=${address?.replace(
                  / /g,
                  "+"
                )}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={styles.linkTag}
              >
                <AreaIcon className={styles.linkSvg} />
              </a>
            </div>

            <div className={cx(styles.linkIcon, styles.linkIconLast)} data-for="website" data-tip="Website">
              <a href={website} target="_blank" rel="nofollow noopener noreferrer" className={styles.linkTag}>
                <WebsiteIcon className={styles.linkSvg} />
              </a>
            </div>

            <div className={cx(styles.ticket)} data-for="link" data-tip="link">
              <a href={link} target="_blank" rel="nofollow noopener noreferrer" className={styles.linkTag}>
                <Ticket className={styles.linkSvg} />
              </a>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.links}>
            <div className={styles.linkIcon} data-for="maps" data-tip="Google Maps">
              <a
                href={`https://www.google.com/maps/search/?api=1&query_place_id=${googleMapsPlaceId}&query=${address?.replace(
                  / /g,
                  "+"
                )}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
                className={styles.linkTag}
              >
                <AreaIcon className={styles.linkSvg} />
              </a>
            </div>

            <div className={styles.linkIcon} data-for="website" data-tip="Website">
              <a href={website} target="_blank" rel="nofollow noopener noreferrer" className={styles.linkTag}>
                <WebsiteIcon className={styles.linkSvg} />
              </a>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <li className={styles.wrapper}>
      <article className={styles.container}>
        <div className={styles.directions}>
          <h2>{stop.navigationTitle}</h2>
        </div>
        <div className={styles.content}>
          {isEvent &&
            ((image?.asset && (
              <button className={styles.imageButton} onClick={openPlaceModal}>
                <Img className={styles.image} alt={title} fixed={image?.asset.fixed} />
              </button>
            )) ??
              null)}
          {!isEvent &&
            ((image?.asset && (
              <button className={styles.imageButton} onClick={openPlaceModal}>
                <Img className={styles.image} alt={title} fixed={image?.asset.fixed} />
              </button>
            )) ??
              null)}

          {isEvent && (
            <Modal
              isOpen={placeModalIsOpen}
              onAfterOpen={afterOpenPlaceModal}
              onRequestClose={closePlaceModal}
              className={styles.placeModal}
              portalClassName={styles.placeModalPortal}
              overlayClassName={styles.placeModalOverlay}
              bodyOpenClassName={styles.placeModalBodyOpen}
              contentLabel="Place Modal"
            >
              <div className={styles.content}>
                {(image?.asset && <Img className={styles.imagePlaceModal} alt={title} fixed={image?.asset.fixed} />) ??
                  null}
                <button onClick={closePlaceModal} className={styles.dissmissWrapper}>
                  <Dismiss />
                </button>
                <div className={styles.nameWrapper}>
                  <h3>{title}</h3>
                </div>
              </div>
            </Modal>
          )}
          {!isEvent && (
            <Modal
              isOpen={placeModalIsOpen}
              onAfterOpen={afterOpenPlaceModal}
              onRequestClose={closePlaceModal}
              className={styles.placeModal}
              portalClassName={styles.placeModalPortal}
              overlayClassName={styles.placeModalOverlay}
              bodyOpenClassName={styles.placeModalBodyOpen}
              contentLabel="Place Modal"
            >
              <div className={styles.content}>
                {(image?.asset && <Img className={styles.imagePlaceModal} alt={title} fixed={image?.asset.fixed} />) ??
                  null}
                <button onClick={closePlaceModal} className={styles.dissmissWrapper}>
                  <Dismiss />
                </button>
                <div className={styles.nameWrapper}>
                  <h3>{title}</h3>
                </div>
              </div>
            </Modal>
          )}
          <div className={styles.text}>
            <h4 className={styles.title}>
              <span
                className={`${styles.stopNumber} ${styles[stopToColorMap[index % stopToColorMap.length].className]}`}
              >
                {index + 1}
              </span>
              {title}
            </h4>
            <div className={styles.subtitle}>
              <div className={styles.lilWrapper}>
                {Number.parseInt(stop.duration) < 180 ? getHumanizedDurationString(stop.duration, 2) : "3 hours +"}{" "}
              </div>

              <div className={styles.lilWrapper}>{priceRange?.value}</div>
              {/* {isEvent && (
                <div className={styles.eventPlace}>
                  <a className={styles.linkPlace}>{ePlace}</a>
                </div>
              )} */}
            </div>
            <div className={styles.description}>
              <BlockContent blocks={description} serializers={serializers} />
            </div>
          </div>
          <TicketButton />
        </div>
      </article>
    </li>
  );
}
